import React from "react";
import { Box, Container, Heading, SimpleGrid, Image, useColorModeValue, VStack, Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionImage = motion(Image);

// IMPORTANT: DO NOT DELETE - Keeping for future reference
/*
const sponsors = [
  {
    name: "Amazon Studios",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo7.png",
    isPremium: true,
  },
  {
    name: "Sponsor 1",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo1.png",
  },
  {
    name: "Sponsor 2",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo2.png",
  },
  {
    name: "Sponsor 3",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo3.png",
  },
  {
    name: "Sponsor 4",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo4.png",
  },
  {
    name: "Sponsor 5",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo5.png",
  },
  {
    name: "Sponsor 6",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/CC_Logo6.png",
  },
  {
    name: "Sponsor 7",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/Untitled+design+(2).png",
  },
  {
    name: "Procolo",
    logo: "https://z-assets.s3.us-west-1.amazonaws.com/sponsors/procolo.png",
  },
];
*/

const SponsorSection = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");

  return (
    <Box bg={bgColor} py={8} display="flex" alignItems="center" justifyContent="center" minHeight="100vh">
      <Container maxW="container.xl" centerContent p={0}>
        <VStack spacing={0} width="100%" align="center" justify="center">
          {/* Amazon Studios Sponsor */}
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <MotionImage
              src="https://assetsjcoholan.s3.us-west-1.amazonaws.com/art_walk_site/SPONSORS.png"
              alt="Amazon Studios"
              maxW={{ base: "800px", md: "1200px", lg: "1600px" }}
              w="100%"
              objectFit="contain"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            />
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default SponsorSection;