import React, { useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Container,
  useColorModeValue,
  VStack,
  Image,
  Link,
  SimpleGrid,
  Button,
  useBreakpointValue,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);

const FadeInWhenVisible = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 1.2, ease: "easeOut" }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 30 }
      }}
    >
      {children}
    </motion.div>
  );
};

const GiveawayPage = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.800", "gray.200");
  const cardBgColor = useColorModeValue("white", "gray.700");

  const prizes = [
    { name: "Two night stay at the Shay Hotel", image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/giveaway_pics/The+Shay+Hotel.png" },
    { name: "10 Class Pack at Pure Barre", image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/giveaway_pics/Pure+Barre.png" },
    { name: "Dancin' Frogs Hammock for Two", image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/giveaway_pics/Dancin'+Frogs+Hammock+for+Two.png" },
    { name: "$100 Pasta Sisters Gift Card", image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/giveaway_pics/Pasta+Sisters.png" },
    { name: "Cognoscenti Coffee & a Peekaboo Coffee mug", image: "https://z-assets.s3.us-west-1.amazonaws.com/cuvler_City_Arts_District/giveaway_pics/Cognoscenti+Coffee.png" },
  ];

  const headingSize = useBreakpointValue({ base: "2xl", md: "3xl", lg: "4xl" });
  const subHeadingSize = useBreakpointValue({ base: "xl", md: "2xl", lg: "3xl" });

  return (
    <Box minHeight="100vh" bg={bgColor} pt={["120px", "140px", "160px"]} pb={20}>
      <Container maxW="container.xl">
        <VStack spacing={[12, 16, 20]} align="stretch">
          {/* Festival Map */}
          <FadeInWhenVisible>
            <Box
              borderRadius="xl"
              overflow="hidden"
              boxShadow="xl"
              maxWidth="800px"
              margin="0 auto"
              mb={8}
            >
              <Heading as="h3" size="lg" textAlign="center" mb={4}>
                Festival Map
              </Heading>
              <Image
                src="https://assetsjcoholan.s3.us-west-1.amazonaws.com/art_walk_site/final_map.jpg"
                alt="Culver City Arts District Festival Map"
                width="100%"
                height="auto"
              />
            </Box>
          </FadeInWhenVisible>

          <FadeInWhenVisible>
            <MotionBox>
              <Heading
                as="h1"
                size={headingSize}
                textAlign="center"
                bgGradient="linear(to-r, purple.400, pink.400)"
                bgClip="text" 
                mb={6}
              >
                Enter the Culver City Art Walk and Roll Festival
              </Heading>
              <MotionHeading
                as="h2"
                size={subHeadingSize}
                textAlign="center"
                fontStyle="italic"
              >
                Giveaway
              </MotionHeading>
            </MotionBox>
          </FadeInWhenVisible>

          <FadeInWhenVisible>
            <MotionText
              fontSize={["xl", "2xl"]}
              textAlign="center"
              color={textColor}
              fontWeight="bold"
            >
              Enter to win one of our 5 amazing prizes!
            </MotionText>
          </FadeInWhenVisible>

          <VStack spacing={[12, 16]} align="stretch">
            <SimpleGrid columns={[1, 2, 3]} spacing={[8, 10, 12]}>
              {prizes.slice(0, 3).map((prize, index) => (
                <FadeInWhenVisible key={index}>
                  <MotionBox
                    bg={cardBgColor}
                    borderRadius="lg"
                    overflow="hidden"
                    boxShadow="lg"
                    _hover={{ transform: "translateY(-5px)", boxShadow: "xl" }}
                  >
                    {/* NOTE: Image temporarily commented out - DO NOT DELETE */}
                    <Image src={prize.image} alt={prize.name} objectFit="cover" height="250px" width="100%" />
                    <Box p={4}>
                      <Text fontWeight="bold" fontSize="lg" textAlign="center">
                        {prize.name}
                      </Text>
                    </Box>
                  </MotionBox>
                </FadeInWhenVisible>
              ))}
            </SimpleGrid>
            <Flex justifyContent="center" width="100%">
              <SimpleGrid columns={[1, 2]} spacing={[8, 10, 12]} width="auto">
                {prizes.slice(3).map((prize, index) => (
                  <FadeInWhenVisible key={index + 3}>
                    <MotionBox
                      bg={cardBgColor}
                      borderRadius="lg"
                      overflow="hidden"
                      boxShadow="lg"
                      _hover={{ transform: "translateY(-5px)", boxShadow: "xl" }}
                    >
                      {/* NOTE: Image temporarily commented out - DO NOT DELETE */}
                      <Image src={prize.image} alt={prize.name} objectFit="cover" height="250px" width="100%" />
                      <Box p={4}>
                        <Text fontWeight="bold" fontSize="lg" textAlign="center">
                          {prize.name}
                        </Text>
                      </Box>
                    </MotionBox>
                  </FadeInWhenVisible>
                ))}
              </SimpleGrid>
            </Flex>
          </VStack>

          <FadeInWhenVisible>
            <Box textAlign="center" my={8}>
              <Link href="https://forms.gle/Yy4P9aeC4fKVQxF7A" isExternal>
                <Button
                  as={motion.button}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  size="lg"
                  colorScheme="purple"
                  px={8}
                  py={4}
                  fontSize="xl"
                >
                  Enter the Giveaway
                </Button>
              </Link>
            </Box>
          </FadeInWhenVisible>

          <FadeInWhenVisible>
            <Box bg={cardBgColor} borderRadius="lg" p={[8, 10, 12]} boxShadow="lg">
              <VStack spacing={10} align="stretch">
                {[
                  { title: "How to Enter:", items: [
                    "Sign up with a valid email address",
                    "One entry per person (multiple entries will be disqualified)",
                    "By entering, you agree to receive occasional email updates from the Culver City Arts District"
                  ]},
                  { title: "Entry Period:", items: [
                    "Begins on 10/1 and ends on 10/18 at 7pm",
                    "No entries accepted after the closing time"
                  ]},
                  { title: "Winner Selection and Notification:", items: [
                    "Winners chosen at random from all eligible entries",
                    "Draw takes place the following week",
                    "Winners announced via email or social media"
                  ]}
                ].map((section, index) => (
                  <MotionBox key={index}>
                    <Text fontSize="2xl" fontWeight="bold" mb={4}>
                      {section.title}
                    </Text>
                    <VStack align="start" spacing={3}>
                      {section.items.map((item, itemIndex) => (
                        <Text key={itemIndex} fontSize="lg">• {item}</Text>
                      ))}
                    </VStack>
                  </MotionBox>
                ))}
              </VStack>
            </Box>
          </FadeInWhenVisible>

          <FadeInWhenVisible>
            <Box bg={cardBgColor} borderRadius="lg" p={[8, 10, 12]} boxShadow="lg">
              <VStack spacing={10} align="stretch">
                <MotionHeading as="h3" size="xl">
                  Support the Arts
                </MotionHeading>
                <MotionText fontSize="lg">
                  Folks, due to the challenging economic climate, this year we lost much of the sponsorship that covers the cost of producing this yearly festival and allows it to be a free public event.
                </MotionText>
                <MotionText fontSize="lg">
                  The Culver City Arts District BID, a nonprofit dedicated to promoting the Arts District, is shouldering the deficit, but we could use your help.
                </MotionText>
                <MotionText fontSize="lg" fontWeight="bold">
                  Any donation, big or small, would go a long way towards supporting the Arts District and this wonderful daylong event.
                </MotionText>
                <Box textAlign="center">
                  <Link href="https://givebutter.com/8MgJGG" isExternal>
                    <Button
                      as={motion.button}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      size="lg"
                      colorScheme="pink"
                      mt={4}
                      px={8}
                      py={4}
                      fontSize="xl"
                    >
                      Donate Now
                    </Button>
                  </Link>
                </Box>
              </VStack>
            </Box>
          </FadeInWhenVisible>
        </VStack>
      </Container>
      <Spacer height={20} />
    </Box>
  );
};

export default GiveawayPage;